<template>
    <div>
        <div v-if="widget.parameters && widget.parameters.length > 0" class="flex px-2 mt-3">
            <template v-for="(param, paramIndex) in getParameterTitles(widget.parameters, module.properties)">
                <WidgetHeader
                    :key="paramIndex"
                    :text="param.title"
                    :parameterId="param.propertyCollectionId"
                    :minimizedParamIds="minimizedParamIds"
                    @toggleWidget="handleClick(param.propertyCollectionId)"
                    @onChecked="setSharedParameter({parameter: param, widget})"
                    :isChecked="activeParameterId == param.id"
                />
            </template>
            <div 
                style="width: 303px;" 
                class="flex fs-14 fw-400 justify-center items-end"
            >Summary</div>
        </div>
        <div >
            <template v-for="(item, index) in filteredCollections">
                <div
                    v-if="widget && widget.parameters && widget.parameters.length > 0"
                    :key="index"
                    :title="`${item.index_code} ${item.title}`"
                >
                <div class="flex">
                    <template 
                        v-for="(parameter, pIndex) in getParameters(widget.parameters)"
                    >
                        <WidgetParameter
                            :key="pIndex"
                            :bg="getParameterBg(getParameterValue(item, parameter), widget.definition_style)"
                            :value="getParameterValue(item, parameter)"
                            :text="getParameterTextDefinition(getParameterValue(item, parameter), widget.definition_style)"
                            :expandedHeight="item.expandedHeight"
                            :minimizedParamIds="minimizedParamIds"
                            :parameterId="parameter.id"
                            :showValue="false"
                        />
                    </template>
                    <WidgetParameter
                        :bg="getParameterBg(getAvgOfProperty(item, widget), widget.definition_style)"
                        :text="getParameterTextDefinition(getAvgOfProperty(item, widget), widget.definition_style)"
                        :expandedHeight="item.expandedHeight"
                        :minimizedParamIds="minimizedParamIds"
                        :showValue="false"
                    />
                </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script setup>
    import { ref } from 'vue';
    import { isEmpty } from 'lodash';
    import WidgetParameter from '@/components/roadmap/visualization/WidgetParameter'
    import WidgetHeader from '@/components/roadmap/visualization/WidgetHeader'
    import useWidgetHeader from '@/components/roadmap/visualization/useWidgetHeader'
    import { getParameterTitles, getParameterTextDefinition, getParameterValue, getParameterBg} from '@/store/modules/roadmap/services.js'
    import useSystemContent from '@/components/shared/visualization/systemContent/useSystemContent';
    const props = defineProps({
        filteredCollections: Array,
        module: Object,
        widget: Object
    });

    const { setSharedParameter, activeParameterId } = useSystemContent();
    const { minimizedParamIds, handleClick } = useWidgetHeader()

    const getParameters = (parameters) => {
        if (parameters && parameters.length > 0) {
            return parameters;
        }
        return [];
    }

    const getAvgOfProperty = (item, widget) => {
        let total = 0
        widget.parameters.map(wd => {
            let property = item.properties?.find(pp => pp.property_collection_id == wd.id)
            if(property) total += property.sample_value
        })
        return (total / widget.parameters.length).toFixed(2)
    }

</script>