<template>
    <div 
        :style="{width: isMinimized ? '88px' : '303px'}" 
        class="flex"
    >
        <div class="flex flex-col items-center">
            <CheckboxBlueIcon
                v-if="showSelect"
                class="w-3 h-3"
                @click.native="$emit('onChecked')"
                :isChecked="isChecked"
            />
            <div v-if="isMinimized" @click="$emit('toggleWidget')" class="font-semibold fs-16 cursor-pointer">+</div>
            <div v-else @click="$emit('toggleWidget')" class="font-semibold fs-16 cursor-pointer">-</div>
        </div>
        <p  v-if="!isMinimized"  class="mt-auto w-72 pl-2 pr-4 text-center fs-14 fw-400 truncate">{{ text }}</p>
    </div>
</template>

<script setup>
    import { computed } from 'vue'
    const props = defineProps({
        text: String,
        parameterId: [String, Number],
        minimizedParamIds: Array,
        isChecked: Boolean,
        showSelect: {
            type: Boolean,
            default: true
        }
    })

    const isMinimized = computed(() => {
        return props.minimizedParamIds.includes(props.parameterId)
    })
</script>