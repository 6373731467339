import { ref } from 'vue'

export default function useWidgetHeader() {
    const minimizedParamIds = ref([])

    const handleClick = (id) => {
        if(_isIdExists(id)) {
            minimizedParamIds.value.splice(_idIndex(id), 1)
            return
        }
        minimizedParamIds.value.push(id)
    }

    function _isIdExists(id) {
        return minimizedParamIds.value.includes(id)
    }

    function _idIndex(id) {
        return minimizedParamIds.value.indexOf(id)
    }

    return {
        minimizedParamIds,
        handleClick
    }
}