<template>
    <div
        :style="{
            backgroundColor: bg,
            height: expandedHeight ? expandedHeight+'px' : '30px',
            width: isMinimized ? '88px' : '303px'
        }"
        class="widget-parameter fs-16 fw-500 flex items-center"
    >
        <div class="flex w-full items-center justify-center">
            <div v-if="showValue" class="value flex justify-center items-center">{{ value }}</div> 
            <span v-if="!isMinimized && showValue" class="fs-10 fw-800">|</span> 
            <p :title="text" style="width: 219px" v-if="!isMinimized" class="fs-14 truncate text-center pl-1">{{ text }}</p>
        </div>
    </div>
</template>


<script setup>
import { computed } from 'vue'

const props = defineProps({
    value: [String, Number],
    text: String,
    isExpanded: Boolean,
    bg: String,
    expandedHeight: [String, Number],
    parameterId: [String, Number],
    minimizedParamIds: Array,
    showValue: {
        default: true
    }
})

const isMinimized = computed(() => {
    return props.minimizedParamIds.includes(props.parameterId)
})
</script>

<style scoped>
.widget-parameter {
    /* width: 303px; */
    /* min-height: 30px; */
    border-right: 1px solid #fff;
    border-left: 1px solid #fff;
    border-top: 0.5px solid #fff;
    border-bottom: 0.5px solid #fff;
    /* padding-left: 18px; */
    color: #fff;
}
.value{
    width: 88px;
    height: 28px;
}
</style>
