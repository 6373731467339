<template>
    <div style="margin-top: 57px">
        <div class="flex i-mb-11 items-center h-3">
        <div class="i-w-220 pl-2 pr-1">
            <!-- @change="updateWidgetSettings" -->
            <select v-model="widget.associated_module_id" name="" id="" class="px-1 text-secondary-two rounded-sm fs-14 fw-400 bg-transparent inst-dropdown param-dropdown w-full">
                <option :value="undefined" disabled selected>Select Module</option>
                <option :value="item.id" v-for="(item, i) in association.getModules(activeRoadmapData)" :key="i">{{ item.title }}</option>
            </select>
        </div>
        <!-- <div>{{ association.getModules(activeRoadmapData).length }}</div> -->
       
        <!-- <template v-if="item.associatedModules.length"> -->
          <template v-for="n in association.maxItemLength">
              <div :key="n" class="flex i-pt-7">
                <p
                :title="item.title"
                style="width: 125px"
                class="fs-14 i-w-61 truncate cursor-pointer text-center text-secondary-two"
                v-for="(item, index) in association.parameterTitles(widget.parameters, association.getModules(activeRoadmapData), selectedModuleId)"
                :key="index"
                >
                {{ item.title }}
              </p>
              <div style="width: 220px"></div>
              </div>
          </template>
        <!-- </template> -->
            
        </div>
        <div
            v-for="(item, index) in association.getFilterCollectionWithAssociatedModules()"
            :key="index"
            class="flex"
        >
            <template v-if="item.associatedModules.length">
                <template v-for="(column, cIndex) in item.associatedModules">
                    <div :key="cIndex">
                        <div class="flex items-center">
                            <div class="flex items-center">
                                <div :style="{ 
                                    background: getDotsBackgroundColor(column,association.getModule(association.getModules(activeRoadmapData),selectedModuleId)),
                                    height: getHeight(item.filterCollection.id, filteredCollections) 
                                }"
                                    @click="setActiveScenarioCollection(association.getModule(association.getModules(activeRoadmapData),selectedModuleId),column)"
                                    class="px-2 flex relative items-center i-w-220 custom-border"
                                    :class="{'border-primary-one': activeData.id === column.id}"
                                >
                                    <span class="truncate text-white ls-5 font-poppins fs-14 fw-300">
                                    {{ column.index_code + " " + column.title }}
                                    </span>
                                </div>
                            </div>
                            <div class="flex">
                                <div
                                    v-for="(value, vIndex) in association.getSampleValues(column.properties, widget.parameters)"
                                    :key="vIndex"
                                    class="custom-border flex items-center justify-center"
                                    style="width: 125px;"
                                    :style="{background: association.getParameterBgByValue(value,widget.definition_style), height: getHeight(item.filterCollection.id, filteredCollections)}"
                                >
                                    <div class="flex i-gap-9 items-center justify-center">
                                        <div
                                            :key="propertyIndex"
                                            v-for="(property, propertyIndex) in 5"
                                            class="m-0 w-2 h-2 rounded-sm custom-margin bg-white"
                                            :class="{'opacity-50': propertyIndex > value}"
                                        ></div>
                                        <span class="fs-14 text-white fw">{{ value }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </template>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import Association from "./association";
import InputSelect from "./InputSelect.vue";
import InputSelectWithVmodel from "./InputSelectWithVmodel.vue";
import { getTheGap } from "@/store/modules/roadmap/services";
import { getItemVisibility } from "@/store/modules/roadmap/services.js";

export default {
  name: "AssociatedContent",
  components: {
    InputSelect,
    InputSelectWithVmodel,
  },
  data() {
    return {
      propertyRange: {
        min: 0,
        max: 0,
      },
      selectedParameterId: null,
      association: new Association(this),
    };
  },
  computed: {
    ...mapGetters({
      activeRoadmapData: "roadmap/programmatic",
      colorCodes: "global/colorCodes",
      activeData: "programmatic/activeScenarioCollection",
    }),
    selectedModuleId() {
      return this.widget.associated_module_id;
    },
    filterCollectionIds() {
      const collectionIdArr = [];
      this.filteredCollections.forEach((item) => {
        collectionIdArr.push(item.id);
      });
      return collectionIdArr;
    },
    moduleLastLabels() {
      let module = this.association.getModule(
        this.association.getModules(this.activeRoadmapData),
        this.selectedModuleId
      );
      if (module) return module.last_levels;
      return [];
    },
  },
  props: [
    "module",
    "layout",
    "widget",
    "flatData",
    "filteredCollections",
  ],
  methods: {
    getGap: getTheGap,
    getItemVisibility,

    getDotsBackgroundColor(collection, module) {
      let collectionSettings = module.collection_settings;
      if (collection && collection.background_color) {
        return collection.background_color;
      }

      collectionSettings = JSON.parse(collectionSettings);

      if (collectionSettings.collections[collection.level].custom_bg) {
        return collectionSettings.collections[collection.level].custom_bg;
      }

      if (collectionSettings && collectionSettings.collections.length > 0) {
        let indexLevel = collection.level - 1;
        let colorCodes = this.colorCodes.slice(
          indexLevel * 9,
          indexLevel * 9 + 9
        );
        return colorCodes[
          collectionSettings.collections[collection.level].color
        ];
      }
      return null;
    },

    setActiveScenarioCollection(module, item) {
      this.$store.commit("programmatic/setActiveBrainstormScenarioCollection", item);
      this.$store.commit("build/setModulePropertySet", module.properties);
      this.$emit("toggleToPropertyMode");
    },

    getHeight(id, filteredCollections) {
      const collection = filteredCollections.find(item => item.id == id)
      if(collection) {
        return collection.expandedHeight ? collection.expandedHeight+'px' : '30px'
      }
      return '30px'
    }
  },

  mounted() {
    this.association.getAssociations(this.filterCollectionIds);
    this.association.moduleId = this.selectedModuleId;
  },
};
</script>

<style>
    .custom-border{
        border-right: 1px solid #fff;
        border-left: 1px solid #fff;
        border-top: 0.5px solid #fff;
        border-bottom: 0.5px solid #fff;
    }
</style>
